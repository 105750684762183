import React from 'react';
import PropTypes from 'prop-types';

import './Logs.css';

import TitleWithUsername from '../common/TitleWithUsername/TitleWithUsername';


class Logs extends React.PureComponent {
	state = {
		activeFilter: 'all'
	};

	updateFilter = (e) => {
		if ((e.target.value !== this.state.activeFilter) !== null) {
			this.setState({ activeFilter: e.target.value });
		}
	};

	render() {
		const { logs, primaryName, clickLogsClear } = this.props;
		const { activeFilter } = this.state;

		// eslint-disable-next-line
		const filteredLogs = logs.filter((logsObj) => {
			if (activeFilter === 'all') {
				return true;
			} else {
				if (logsObj.type === activeFilter) {
					return true;
				}
			}
		});

		return (
			<div className="logs">
				<TitleWithUsername title="logs" primaryName={primaryName} />

				<div className="logs-section mt-1 logs-scrollbar">
					<button
						onClick={clickLogsClear}
						className="btn align-self-end clear-btn"
						>
						<i className={`material-icons`}>clear_all</i>
					</button>
					<ul>
						{filteredLogs.map(({ time, message }, i) => (
							<li className="text-font mt-1" key={i}>
								<div className="d-flex align-items-center">
									<span className="badge badge-secondary mr-1">
										{time}
									</span>
									<span>{message}</span>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}
}

Logs.propTypes = {
	logs: PropTypes.array.isRequired
};

export default Logs;
