import { EXPORT_APIS } from "../../../../../constants/endPointsURLs";

export const data = [
    {
        id: 1,
        label: 'LIVE OMNI - SRT',
        name: 'LIVE OMNI - SRT',
        text: ' Export will be generated upon clicking "Next". Additional export configuration can be done on the Export tab.',
        cbUrl: EXPORT_APIS.generateExportSRT,
    },
    {
        id: 2,
        label: 'LIVE OMNI - RTMP',
        name: 'LIVE OMNI - RTMP',
        text: ' Export will be generated upon clicking "Next". Additional export configuration can be done on the Export tab.',
        cbUrl: EXPORT_APIS.generateExportRTMP,
    },
    {
        id: 3,
        label: 'CUSTOM RTMP',
        text: 'CUSTOM RTMP',
        cbUrl: EXPORT_APIS.setExport,
    },
    {
        id: 4,
        label: 'SRT CALLER',
        text: 'SRT CALLER',
        cbUrl: EXPORT_APIS.setExport,
    },
    {
        id: 5,
        label: 'SRT LISTENER',
        name: 'SRT LISTENER',
        text: ' Export will be generated upon clicking "Next". Additional export configuration can be done on the Export tab.',
        cbUrl: EXPORT_APIS.setExport,
    },
];
