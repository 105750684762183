import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FaGlobe , FaFacebook, FaYoutube, FaTwitter, FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import Modal from '../common/Modal/Modal';
import Notification from '../Notification/Notification';
import './Header.css';
import { GuestCounter } from '../GuestMode/components/GuestCounter/GuestCounter';
import { GuestGetMoreGuestsModal } from '../GuestMode/components/GuestGetMoreGuestsModal';
import { GuestModeModal } from '../GuestMode/components/GuestModeModal';
import { fetchUserCountry } from '../GuestMode/form/utils/userCountryUtils';
import config from '../../dashboard_config.json';

const Header = ({
  dashboardCompanyName,
  dashboardAdminFirstName,
  dashboardAdminLastName,
  logout,
  focusedPrimary,
  guestsInfo,
  filteredOutputs,
  companyType,
  isAtLeastOneCorporateLicense,
  showNotification,
  handlePrimaryFocus,
  primaryUsers,
  getControllerInfo,
  fetchExportList,
  setDisplayedName,
  ...props
}) => {
  const [guestModeModalState, setGuestModeModalState] = useState({
    open: false,
  });

  const [dropdownActive, setDropdownActive] = useState(false);
  const showProfileTab = config.dashboard.headerSettings.showProfile;

  const {
    showSocialIcons,
    omnistreamWebUrl,
    facebookUrl,
    youtubeUrl,
    instagramUrl,
    linkedInUrl,
    twitterUrl,
    tiktokUrl
  } = config.dashboard.headerSettings.socialIcons;

  const { guestsTotal, guestsTotalHistory, guestsRemain, guestsFreeTrialRemain, guestsMax, guestsCurrentCount } = guestsInfo;
  const isAvailableGetGuests = guestsRemain > 0 || guestsFreeTrialRemain > 0;
  const isGuestReachedLimit = guestsCurrentCount >= guestsMax;

  const [dataTargetGuestsModalValue, setDataTargetGuestsModalValue] = useState('#create-guest-mode-modal');

  useEffect(() => {
    const determineModalValue = async () => {
      if (isAvailableGetGuests && isGuestReachedLimit) {
        setDataTargetGuestsModalValue('#guests-reach-limit-modal');
      } else if (!isAvailableGetGuests) {
        setDataTargetGuestsModalValue('#get-guest-mode-modal');
      } else {
        setDataTargetGuestsModalValue('#create-guest-mode-modal');
        let userCountry = localStorage.getItem('userCountry');
        if (!userCountry) {
          userCountry = await fetchUserCountry();
        }
      }
    };

    determineModalValue();
  }, [isAvailableGetGuests, isGuestReachedLimit]);

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const closeDropdown = () => {
    setDropdownActive(false);
  };

  return (
    <div className="navbar-omni navbar navbar-expand-lg navbar-light">
      <div className="omni-logo navbar-brand"></div>
      <div className="seperator" />
      <ul className="links-container navbar-nav mr-auto">
        <li className="nav-item p-2">
          <NavLink to="/dashboard" className="nav-link">
            DASHBOARD
          </NavLink>
        </li>

        {companyType === 0 && (
          <li className="nav-item p-2">
            <NavLink to="/map" className="nav-link">
              MAP
            </NavLink>
          </li>
        )}

        {companyType === 0 && isAtLeastOneCorporateLicense && (
          <>
            <li className="nav-item p-2" data-toggle="modal" data-target={dataTargetGuestsModalValue}>
              <div
                className="create-guest-mode-modal-button"
                onClick={
                  dataTargetGuestsModalValue === '#create-guest-mode-modal'
                    ? () => setGuestModeModalState({ open: true })
                    : null
                }
              >
                INVITE GUEST
              </div>
            </li>

            <li className="nav-item p-2">
              <GuestCounter
                guestsTotal={guestsTotal}
                guestsTotalHistory={guestsTotalHistory}
                guestsRemain={guestsRemain}
                guestsFreeTrialRemain={guestsFreeTrialRemain}
                guestsMax={guestsMax}
              />
            </li>
          </>
        )}
      </ul>
      <div className="notification-container">
        <Notification {...props} />
      </div>
      <div className="user-container">
        <div className="user-info" onClick={toggleDropdown}>
          <div className="username-circle">{dashboardCompanyName.slice(0, 2).toUpperCase()}</div>
        </div>
        <div className={`dropdown-menu ${dropdownActive ? 'active' : ''}`} onMouseLeave={closeDropdown}>
          <div className="dropdown-user-info" style={{ pointerEvents: 'none' }}>
            <div className="username-circle">{dashboardCompanyName.slice(0, 2).toUpperCase()}</div>
            <div className='company-name'>{dashboardCompanyName}</div>
            {/* {dashboardAdminFirstName} {dashboardAdminLastName} */} {/* uncomment to show First and last naeme */}
          </div>
          {/* <div className='companyName'>{dashboardCompanyName}</div> */} {/* company name below username */}
          <div className="separator" style={{ pointerEvents: 'none' }}></div>
          {showProfileTab && (
            <div className="dropdown-menu-item">
              <span className="material-icons">person</span>
              <span>Profile</span>
            </div>
          )}
          <div className="dropdown-menu-item" href="#" data-toggle="modal" data-target="#logout-modal">
            <span className="material-icons">logout</span>
            <span>Logout</span>
          </div>
          {showSocialIcons && (
            <div className="dropdown-menu-social-icons">
              <a href={omnistreamWebUrl} target="_blank" rel="noopener noreferrer">
                <FaGlobe />
              </a>
              <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
                <FaFacebook />
              </a>
              <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </a>
              <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
              <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
              <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
              <a href={tiktokUrl} target="_blank" rel="noopener noreferrer">
                <FaTiktok />
              </a>
            </div>
          )}
        </div>
      </div>
      <Modal
        uniqueId="logout-modal"
        headerTitle="Logout"
        buttons={[
          { name: 'Yes', clickHandler: logout, className: 'danger danger-submit-btn' },
          { name: 'No', className: 'danger danger-submit-btn' },
        ]}
        bodyClass="modal-body modal-body-logout"
      >
        Are you sure you want to logout?
      </Modal>
      <Modal
        uniqueId="guests-reach-limit-modal"
        headerTitle={<span className="danger-title">Concurrent Invitations Limit Reached</span>}
        buttons={[{ name: 'Close', className: 'danger danger-submit-btn' }]}
        bodyClass="modal-body modal-body-guests-reach-limit"
      >
        Guest limit reached.
        <br />
        Please use existing guests before generating new ones.
      </Modal>
      <GuestGetMoreGuestsModal />
      <GuestModeModal
        guestModeModalState={guestModeModalState}
        setGuestModeModalState={setGuestModeModalState}
        filteredOutputs={filteredOutputs}
        guestsMax={guestsMax}
        showNotification={showNotification}
        dashboardCompanyName={dashboardCompanyName}
        handlePrimaryFocus={handlePrimaryFocus}
        primaryUsers={primaryUsers}
        focusedPrimary={focusedPrimary}
        getControllerInfo={getControllerInfo}
        fetchExportList={fetchExportList}
        setDisplayedName={setDisplayedName}
      />
    </div>
  );
};

Header.propTypes = {
  dashboardCompanyName: PropTypes.string.isRequired,
  dashboardAdminFirstName: PropTypes.string.isRequired,
  dashboardAdminLastName: PropTypes.string.isRequired,
};

export default React.memo(Header);
