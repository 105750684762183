export const LicensePermissions = {
    CORPORATE: 0,
    VIDEOGRAPHER: 1,
    INFLUENCER: 2,
    FREE_TRIAL: 3,
    MYKEY: 4,
    BASIC: 5,
    LIVE_OMNI: 6,
    GUEST: 7,

    CORPORATE_NAME: "CORPORATE",
    VIDEOGRAPHER_NAME: "VIDEOGRAPHER",
    INFLUENCER_NAME: "INFLUENCER",
    FREE_TRIAL_NAME: "FREE TRIAL",
    MYKEY_NAME: "MYKEY",
    BASIC_NAME: "BASIC",
    LIVE_OMNI_NAME: "LIVE OMNI",
    GUEST_NAME: "GUEST"
};

export const getPermissionNameById = (id) => {
    switch (id) {
        case Permissions.CORPORATE:
            return Permissions.CORPORATE_NAME;
        case Permissions.VIDEOGRAPHER:
            return Permissions.VIDEOGRAPHER_NAME;
        case Permissions.INFLUENCER:
            return Permissions.INFLUENCER_NAME;
        case Permissions.FREE_TRIAL:
            return Permissions.FREE_TRIAL_NAME;
        case Permissions.MYKEY:
            return Permissions.MYKEY_NAME;
        case Permissions.BASIC:
            return Permissions.BASIC_NAME;
        case Permissions.LIVE_OMNI:
            return Permissions.LIVE_OMNI_NAME;
        case Permissions.GUEST:
            return Permissions.GUEST_NAME;
        default:
            return "";
    }
};
