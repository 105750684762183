import React from 'react';
import PropTypes from 'prop-types';
import "./ProvidersChart.css";
import { Chart } from "react-google-charts";

// ----------- ProvidersChart.js local constants ----------- //
const SCHEDULER_WAIT_TIME = 1000;

const DARK_BLUE_COLOR = '#457cbc'; // Omnistream dark blue color
const DARK_GRAY_COLOR = '#343a40'; // Bootstrap "dark" color
const GRAY_COLOR = '#7d8891'; // Bootstrap "secondary" color
// ------------------------------------------------------- //

class ProvidersChart extends React.Component {
	state = {
		data: [["Name", "Load"]]
	};

	componentDidMount() {
		this.clearChartUrl();
	}

	clearChartUrl() {
		this.setState({ data: [["Name", "Load"]] });
		// this.state.data = [["Name", "Load"]];
	}

	createChartUrl(broadcastDetailsJsonObject) {
        let broadcastOperatorsJsonArray = broadcastDetailsJsonObject["providers"];
        let totalDeliveredPackets = broadcastDetailsJsonObject["totalDeliveredPackets"];
		if (broadcastOperatorsJsonArray===undefined){
			// this.clearChartUrl();
		}else{
			let data = [["Name", "Load"]];
			for (let index=0;index<broadcastOperatorsJsonArray.length;index++){
				let broadcastOperatorJsonObject = broadcastOperatorsJsonArray[index];
				let deliveredPackets = broadcastOperatorJsonObject["deliveredPackets"];
				let chartPortionParam = Math.round((deliveredPackets * 100) / totalDeliveredPackets);
				let chartNamesParam = broadcastOperatorJsonObject["providerName"];
				data.push([chartNamesParam,chartPortionParam]);
			
			}
			// this.setState({ data: data });
			if (data.length>0)
				this.state.data = data;
		}
    }

	componentDidUpdate(prevProps) {
		const {
			isPrimaryBroadcasting,
			focusedPrimaryId,
			broadcastDetailsJsonObject,
			isProviderChartDisplayed
		} = this.props;
		if (isProviderChartDisplayed){
			if (focusedPrimaryId !== prevProps.focusedPrimaryId) {
				this.clearChartUrl();
			}else if (broadcastDetailsJsonObject !== undefined && broadcastDetailsJsonObject["primaryId"] === focusedPrimaryId && isPrimaryBroadcasting === true) {
				this.createChartUrl(broadcastDetailsJsonObject);
			}
		}
	}

	componentWillUnmount() {
	
	}

	render() {
		var options = {
			// is3D: true,
			backgroundColor: 'transparent',
			legend: {
				position: "right",
				alignment: "center",
				textStyle: {
				color: "f4efe9",
				fontSize: 14
				}
			  },
			tooltip: {
				showColorCode: true
			},
			chartArea: {
				left: 0,
				top: 40,
				width: "100%",
				height: "80%"
			},
		  };
		return (
			<Chart
			chartType="PieChart"
			data={this.state.data}
			options={options}
			graph_id="PieChart"
			width={"100%"}
			height={310}
			legend_toggle
		  />
		);
}
}

ProvidersChart.propTypes = {
	screenSize: PropTypes.string.isRequired,
	isProviderChartDisplayed: PropTypes.bool.isRequired
};

export default ProvidersChart;
