const availableBroadcastSettings = [
	{
		id: 'camera',
		name: 'Camera',
		valuesArray: [
			{ value: 'internal-back', displayedName: 'Internal - Back', type: 0 },
			{ value: 'internal-front', displayedName: 'Internal - Front', type: 1},
			{ value: 'external', displayedName: 'External - Encoder', type: 2 },
			{ value: 'external-adapter', displayedName: 'External - Adapter (UVC)', type: 3 }
		],
		type: 'video_setting',
		isDynamic: false
	},
	{
		id: 'scanMode',
		name: 'Scan',
		valuesArray: [
			{ value: 'progressive', displayedName: 'Progressive', type: 0 },
			{ value: 'interlaced', displayedName: 'Interlaced', type: 1 }
		],
		type: 'video_setting',
		isDynamic: false
	},
	{
		id: 'resolution',
		name: 'Resolution',
		valuesArray: [
			{ value: 'sd', displayedName: 'SD', type: 0 },
			{ value: 'hd', displayedName: '1280 x 720', type: 1 },
			{ value: 'full-hd', displayedName: '1920 x 1080', type: 2 },
			{ value: 'ultra-hd', displayedName: '3840 x 2160', type: 3 }
		],
		type: 'video_setting',
		isDynamic: false
	},
	{
		id: 'codec',
		name: 'Codec',
		valuesArray: [
			{ value: 'H.264', displayedName: 'H.264', type: 0 },
			{ value: 'H.265', displayedName: 'H.265', type: 1 }
		],
		type: 'video_setting',
		isDynamic: false
	},
	{
		id: 'frameRate',
		name: 'FPS',
		valuesArray: [
			{ value: '60', displayedName: '60 fps', type: 0 },
			{ value: '50', displayedName: '50 fps', type: 1 },
			{ value: '30', displayedName: '30 fps', type: 2 },
			{ value: '25', displayedName: '25 fps', type: 3 },
			{ value: '24', displayedName: '24 fps', type: 4 }
		],
		type: 'video_setting',
		isDynamic: false
	},
	{
		id: 'maxBitrate',
		name: 'Bitrate',
		valuesArray: [
			{ value: '1', displayedName: '1 Mb/s', type: 0 },
			{ value: '2', displayedName: '2 Mb/s', type: 1 },
			{ value: '3', displayedName: '3 Mb/s', type: 2 },
			{ value: '4', displayedName: '4 Mb/s', type: 3 },
			{ value: '5', displayedName: '5 Mb/s', type: 4 },
			{ value: '6', displayedName: '6 Mb/s', type: 5 },
			{ value: '8', displayedName: '8 Mb/s', type: 6 },
			{ value: '12', displayedName: '12 Mb/s', type: 7 },
			{ value: '16', displayedName: '16 Mb/s', type: 8 },
		],
		type: 'video_setting',
		isDynamic: true
	},
	{
		id: 'streamType',
		name: 'Profile',
		valuesArray: [
			{ value: 'live', displayedName: 'Live', type: 0 },
			{ value: 'average', displayedName: 'Average', type: 1 },
			{ value: 'best-quality', displayedName: 'Best Quality', type: 2 },
			{ value: 'record', displayedName: 'Record', type: 3 },
			{ value: 'record-and-play', displayedName: 'Record and Play', type: 4 }
		],
		type: 'session_setting',
		isDynamic: false
	},
	{
		id: 'videoFluency',
		name: 'Fluency',
		valuesArray: [
			{ value: 'level-a', displayedName: 'Level A', type: 0 },
			{ value: 'level-b', displayedName: 'Level B', type: 1 },
			{ value: 'level-c', displayedName: 'Level C', type: 2 }
		],
		type: 'session_setting',
		isDynamic: false
	},
	{
		id: 'broadcastType',
		name: 'Mode',
		valuesArray: [
			{ value: 'bonding', displayedName: 'Bonding', type: 0 },
			{ value: 'redundancy', displayedName: 'Redundancy', type: 1 }
		],
		type: 'session_setting',
		isDynamic: false
	}
];

export default availableBroadcastSettings;
