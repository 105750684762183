import React from 'react';
import PropTypes from 'prop-types';

import './ControlButtons.css';

import Button from '../common/Button/Button';

const ControlButtons = ({
  focusedPrimaryId,
  isChangingStartStopInProgress,
  isPrimaryBroadcasting,
  streamType,

  clickStartStop,
  clickIFBmouseDown,
  clickIFBmouseUp,

  onAudioSelect,
  audioDevices,
  selectedDevice,
  isAudioChannelOpen,
  companyType,
}) => {
  let iconName;
  if (isPrimaryBroadcasting === true) {
    iconName = 'stop';
  } else {
    switch (streamType) {
      case 'live':
      case 'average':
      case 'best-quality':
        iconName = 'play_arrow';
        break;

      case 'record':
        iconName = 'fiber_manual_record';
        break;

      case 'record-and-play':
        iconName = 'cloud_upload';
        break;

      default:
        iconName = 'play_arrow';
    }
  }

  return (
    <div className="control-buttons p-2 mx-2 row">
      <div className="col">
        {!isChangingStartStopInProgress ? (
          <Button
            uniqueId="play-stop-btn"
            iconName={iconName}
            isDisabled={!focusedPrimaryId}
            clickHandler={clickStartStop}
          />
        ) : (
          <Button uniqueId="play-stop-btn" showSpinner={true} clickHandler={() => {}} />
        )}
      </div>

      <div className={`${companyType === 0 ? 'col' : 'invisible'}`}>
        <Button
          iconName={isAudioChannelOpen ? 'mic' : 'mic_none'}
          isDisabled={selectedDevice ? Object.keys(selectedDevice.primaries).length == 0 : true}
          clickHandler={() => {}}
          mouseDownHandler={() => {
            clickIFBmouseDown(selectedDevice);
          }}
          mouseUpHandler={() => {
            clickIFBmouseUp(selectedDevice);
          }}
        />
      </div>
      <div className={`${companyType === 0 ? 'col' : 'invisible'}`}>
        <Button
          bsClassSuffix="secondary"
          iconName={isAudioChannelOpen ? 'lock' : 'lock_open'}
          isDisabled={selectedDevice ? Object.keys(selectedDevice.primaries).length === 0 : true}
          clickHandler={() => {
            if (isAudioChannelOpen) {
              clickIFBmouseUp(selectedDevice);
            } else {
              clickIFBmouseDown(selectedDevice);
            }
          }}
        />
      </div>

      <div className={`${companyType === 0 ? 'col' : 'invisible'}`}>
        <div className="dropdown">
          <button
            className="btn btn-background dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <i className="icon-size material-icons">
              {selectedDevice ? '' : 'settings_input_component'}
            </i>
            {selectedDevice.name || 'Audio source...'}
          </button>
          <div
            className="dropdown-menu dropdown-menu-no-padding"
            aria-labelledby="dropdownMenuButton">
            {audioDevices.map((device) => (
              <div className="hr" key={device.id}>
                <a
                  onClick={() => {
                    onAudioSelect(device);
                  }}
                  className="dropdown-item click-item">
                  {device.name}
                  <div>
                    {Object.keys(device.primaries).map(
                      (item, i) =>
                        device.primaries[item] && (
                          <span className="text-info text-margin" key={i}>
                            {device.primaries[item]}
                          </span>
                        ),
                    )}
                  </div>
                </a>

                {/* {primaryUsers.map(primary => (
						<div key={primary.id}>
								<a
									className='dropdown-item click-item'
									>
									{primary.username}
									
								</a>
								
								</div>
							))} */}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className='col'>
				<button
					id='wrench'
					className='btn btn-outline-secondary btn-sm d-flex justify-content-center align-items-center'
					data-toggle='modal'
					data-target='#ifb-modal'>
					<i className='material-icons'>build</i>
				</button>
			</div> */}
    </div>
  );
};

ControlButtons.propTypes = {
  focusedPrimaryId: PropTypes.number,
  isChangingStartStopInProgress: PropTypes.bool.isRequired,
  streamType: PropTypes.string,
  isPrimaryBroadcasting: PropTypes.oneOf([undefined, false, true]),
  screenSize: PropTypes.string,
  companyType: PropTypes.number,
};

export default React.memo(ControlButtons);
